import './index.scss'
import Swiper from 'swiper/js/swiper.min.js'
// import '../../assets/scripts/jquery.waypoints.min.js'
// import '../../assets/scripts/jquery.countup.min.js'

// $('.counter').countUp();

var bannerswiper = new Swiper(".index-banner .swiper", {
  speed: 1000,
  watchOverflow: true,
  autoplay: {
    delay: 10000,
    stopOnLastSlide: false,
    disableOnInteraction: false,
    waitForTransition: false,
  },
  pagination: {
    el: '.index-banner .swiper-pagination',
    clickable: true,
    renderBullet: function (index, className) {
      return '<div class="' + className + '"><svg><circle></circle></svg></div>';
    },
  },
  navigation: {
    nextEl: ".index-banner .swiper-button-next",
    prevEl: ".index-banner .swiper-button-prev"
  },
  on: {
    init: function(swiper){
      // console.log(this.slides.length)
      if(this.slides.length > 1){
        let custompageNum = this.slides.length
        let custompagehtml = ''
        for(let i=0; i < custompageNum; i++){
          let key = i+1
          custompagehtml += `<span>${key > 9 ? key : '0'+key}</span>`
        }
        $('.index-banner .swiper-btn .custom-pagination').html(custompagehtml)
        $('.index-banner .swiper-btn .custom-pagination span').eq(0).addClass('active')
      }else{
        $('.index-banner .swiper-pagination,.index-banner .swiper-btn').hide()
      }
    },
    slideChangeTransitionStart: function(){
      $('.index-banner .swiper-btn .custom-pagination span').eq(this.realIndex).addClass('active').siblings().removeClass('active')
    },
  }
});

$('.index-banner .swiper-btn .custom-pagination').on('click','span',function(){
  let index = $(this).index()+1
  bannerswiper.slideTo(index)
})



var swiper1 = new Swiper(".section2 .sec2-swiper .mySwiper", {
  slidesPerView: 1,
  slidesPerGroup : 1,
  spaceBetween: 20,
  watchOverflow: true,
  centerInsufficientSlides: true,
  pagination: {
    el: ".section2 .sec2-swiper .swiper-pagination",
    clickable: true
  },
  breakpoints: {
    1280:{
      slidesPerView : 3,
      slidesPerGroup : 3,
      spaceBetween : 55,
    },
    1024: {
      slidesPerView: 3,
      slidesPerGroup : 3,
      spaceBetween: 30,
    },
    768: {
      slidesPerView: 2,
      slidesPerGroup : 2,
      spaceBetween: 20,
    },
    640: {
      slidesPerView: 1,
      slidesPerGroup : 1,
      spaceBetween: 20,
    }
  } 
});
var swiper2 = new Swiper(".section3 .sec3-swiper .mySwiper", {
  slidesPerView: 1,
  slidesPerGroup : 1,
  spaceBetween: 10,
  watchOverflow: true,
  centerInsufficientSlides: true,
  pagination: {
    el: ".section3 .sec3-swiper .swiper-pagination",
    clickable: true
  },
  breakpoints: {
    1280:{
      slidesPerView : 4,
      slidesPerGroup : 4,
      spaceBetween : 20,
    },
    1024: {
      slidesPerView: 3,
      slidesPerGroup : 3,
      spaceBetween: 20,
    },
    768: {
      slidesPerView: 2,
      slidesPerGroup : 2,
      spaceBetween: 20,
    },
    640: {
      slidesPerView: 1,
      slidesPerGroup : 1,
      spaceBetween: 10,
    }
  } 
});

$(document).ready(function(){
  // 视频相关
  var $video = $('.index-banner video')
  $video.each(function(index){
    this.addEventListener('play', function () { //播放开始执行的函数
      $(this).closest('.video').find('.video-play').hide()
    });
    this.addEventListener('pause', function () { //暂停开始执行的函数
      $(this).closest('.video').find('.video-play').show()
    });
  })
  $('.index-banner .video .video-play').on('click',function(){
    let $parent = $(this).closest('.video')
    $parent.find('video')[0].play()
    $(this).hide()
  })
})